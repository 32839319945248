import React, { ChangeEvent, Component, Fragment, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import './StandardInput.scss';
import IStandardInput from '../interfaces/StandardInput';
import {FormContext} from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';
import { RSA_PKCS1_OAEP_PADDING } from 'node:constants';

interface IProps {
  component:IStandardInput;
  parentAlias:string;
  formDirty:boolean;
  validationHandler(alias:string, isValid:boolean):void;
}

const StandardInput:FunctionComponent<IProps> = props => {
  
  const usDateFormat = 'mm/dd/yyyy';
  const caDateFormat = 'dd/mm/yyyy';

  const formContext = useContext(FormContext);

  const [inputValue, setInputValue] = useState<string>(getValue());
  let [isValid, setIsValid] = useState<boolean>();
  let [isTouched, setIsTouched] = useState<boolean>(false);
  let [hasFocus, setHasFocus] = useState<boolean>(false);
  let [hasError, setHasError] = useState<boolean>(false);

  let [tempNumber, setTempNumber] = useState<number>(0.00);

  const inputRef:any = useRef(null);
  
  function getAlias():string {
    return props.parentAlias + '.' + props.component.alias;
  }

  function onValueChange(e:React.ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLTextAreaElement>) {
    //setIsTouched(true);
    let value = e.target.value;
    if(props.component.format && props.component.format === 'number' ) {
      if(value) {
        if(props.component.showCommas === false) {
          value = value.replace(/\D/g,'');
        } else {
          value = Number(value.replace(/\D/g,'')).toLocaleString('en-US');
        }
        
      } 
    }
    if(props.component.format && props.component.format === 'currency') {
      
      if(value) {
        
          value = Number(value.replace('.00','').replace(/\D/g,'')).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
          forceCursor();
        
      } 
    }
    if(formContext.updateFormData) {
      formContext.updateFormData(getAlias(), value);
      setInputValue(value);
      
    }
    
  }
  
  function forceCursor() {
     if (inputRef) {
       inputRef.current.selectionStart = inputRef.current.value.length - 3;
       inputRef.current.selectionEnd = inputRef.current.value.length - 3;
     }
  }



  function getValue():string {
    let value = formContext.formData?.get(getAlias());
    
    if(value) {
      return value;
    } else {
      return '';
    }
  }

  function validate() {
    
    
    //if (isTouched) {
     //if(inputValue) {
     //  console.log(inputValue);
     //}
    
    
    let isFieldValid = true;
    let override = false;
    
    let passesValidationRule = true;


    
    

    if(!override && props.component.required) {
      if(!inputValue || !inputValue.trim()) {
        isFieldValid = false;  
      }
    }

    //if its a date and required
    if(props.component.format === 'date') {
      if(props.component.required) {
        isFieldValid = validateDate(inputValue);
      }
    }

    if(props.component.format === 'email') {
      if(props.component.required) {
        isFieldValid = validateEmail(inputValue);
      }
    }

    if(props.component.format === 'ssn') {
      if(props.component.required) {
        if(inputValue.includes("_")) {
          isFieldValid = false;
        }
      }
    }

    if(props.component.format === 'ssn4') {
      if(props.component.required) {
        if(inputValue.includes("_")) {
          isFieldValid = false;
        }
      }
    }
    
    if(props.component.format === 'ein') {
      if(props.component.required) {
        if(inputValue.includes("_")) {
          isFieldValid = false;
        }
      }
    }

    if(props.component.format === 'zip') {
      if(props.component.required) {
        if(inputValue.includes("_")) {
          isFieldValid = false;
        }
      }
    }

    if(props.component.format === 'phone') {
      if(props.component.required || inputValue) {
        if(inputValue.includes("_")) {
          isFieldValid = false;
        }
      }
    }


    if(!override && props.component.validationRule) {
      //console.log('called');
      
      let validationRule = props.component.validationRule.split(' ');
      //console.log(validationRule);
      //console.log(validationRule[1]);
      let checkValue = formContext.formData?.get(validationRule[1]);

      
      
      let checkValues = [];
      

      if (!checkValue) {
        if(validationRule[1].indexOf("*") !== -1) {
          let index = 0;
          while(true) {
            index = index + 1;
            let checkIndexAlias = validationRule[1].replace('*', String(index));
            
            let indexKey = formContext.formData?.get(checkIndexAlias);
            if(indexKey && checkIndexAlias != getAlias()) {
              checkValues.push(indexKey);
            } else {
              break;
            }
          }
        } else {
          checkValue = validationRule[1];
        }
      }

      
      if(checkValue && checkValues.length === 0) {
        checkValues = [checkValue];
      } 
      //console.log(`checked value: ${checkValue} input value: ${inputValue}`)
      
      checkValues.forEach((checkValue:string) => {
        if (validationRule[0] === 'length') {
        
          if (Number(checkValue) != inputValue.length) {
            passesValidationRule = false;
          }
        }
        if (validationRule[0] === 'eq') {
          if (checkValue != inputValue) {
            passesValidationRule = false;
          }
        }
        
        if (validationRule[0] === 'gte') {
          if (!checkValue) {
            checkValue = '0';
          }
          let num1 = Number(checkValue.replaceAll(',',''));
          let num2 = Number(inputValue.replaceAll(',',''));
          //console.log(num1 + "  >  " + num2);
          if (num1 > num2) {
            passesValidationRule = false;
          }
        }
        if (validationRule[0] === 'neq') {
          if (checkValue === inputValue) {
            passesValidationRule = false;
          }
        }
        
      });
      
    }
    
      //console.log(getAlias() + " " + inputValue + " " + String(isFieldValid && passesValidationRule));
      setIsValid(isFieldValid && passesValidationRule);
      props.validationHandler(getAlias(), (isFieldValid && passesValidationRule));
    //}
  }

  function overrideChange(event:React.ChangeEvent<HTMLInputElement>) {
    
    if (event.target.checked) {
      cloneFields(true);
    } else {
      cloneFields(false);
    }
    
  }

  React.useEffect(() => {
    
   validate();
  }, [inputValue])

  React.useEffect(() => {
    let value = getValue()
    if(value !== inputValue) {
      setIsTouched(true);
      setInputValue(value);
      if(formContext.updateFormData) {
        formContext.updateFormData(getAlias(), value);
      }
    }
    validate();
   }, [formContext.formRevision])
 


  function blurHandler() {
    setIsTouched(true);
    validate();
    setHasFocus(false);
  }
  
  function focusHandler() {
    // if (props.component.format && props.component.format === 'currency' && !inputValue) {
    //   setInputValue('0.00');
    // }
    setHasFocus(true);
  }

  React.useEffect(() => {
    if(isTouched && !isValid) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [hasFocus, isTouched, isValid])

  React.useEffect(() => {
    if(props.formDirty) {
      setIsTouched(true);
      validate();
    }
   
   }, [props.formDirty])

  function validateDate(date:string) {
    if(date) {
      let dateParts = date.split('/');
      
      if (formContext.formData?.get('system.country') === 'us') {
        let year = Number(dateParts[2]);
        let month = Number(dateParts[0]) - 1;
        let day = Number(dateParts[1]);
        let composedDate = new Date(year, month , day );
        return composedDate.getDate() == day &&
            composedDate.getMonth() == month &&
            composedDate.getFullYear() == year;
      } else {
        let year = Number(dateParts[2]);
        let month = Number(dateParts[1]) - 1;
        let day = Number(dateParts[0]);
        let composedDate = new Date(year, month , day );
        return composedDate.getDate() == day &&
            composedDate.getMonth() == month &&
            composedDate.getFullYear() == year;
      }
    } else {
      return false;
    }

  } 

  function getSsnMask():string {
    

    
    return formContext.formData?.get('system.country') === 'us' ? "999-99-9999" : "999-999-999";
  }

  function getEinMask():string {
    
    return formContext.formData?.get('system.country') === 'us' ? "99-9999999" : "999999999";
  }

  function validateEmail(email:string) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    function cloneFields(add:boolean) {
      if(props.component.overrideFields && props.component.overrideFields.length > 0) {
        props.component.overrideFields.forEach((override:string) =>
          {
            let overrideFields = override.split("|");
            
            let mainValue = formContext.formData?.get(overrideFields[0]);
            if (formContext.updateFormData) {
              if(add) {
                formContext.updateFormData(overrideFields[1], mainValue || '');
              } else {
                formContext.updateFormData(overrideFields[1], '');
              }
              
            }
            
          }
        )
      }
    }

    function getInputMode():"email" | "text" | "none" | "tel" | "url" | "numeric" | "decimal" | "search" | undefined {
      if (props.component.format === 'email') {
        return "email";
      } else if (props.component.format === 'number') {
        return "numeric";
      } else {
        return undefined;
      }
    }

  return (
    <Fragment>
      
    <div className={"standard-input " + (hasError ? 'error' : '')}>
        {props.component.canOverride && <label className="override"><input type="checkbox" onChange={overrideChange} /><KeyReplace string={props.component.overrideLabel || ''} /> </label>}
        <label className={(props.component.hideLabel === true ? 'hidden' : '') + (props.component.showDollar ? 'dollar' : '')} htmlFor={getAlias()}><KeyReplace string={props.component.label} />{props.component.required && <span className="required">*</span>}</label>
        {(!props.component.format || props.component.format === "number" || props.component.format === "email") && <input maxLength={(props.component.maxChars || 128)} inputMode={getInputMode()}  pattern={props.component.format === 'number' ? "[0-9.,]+" : ''} onBlur={blurHandler} onFocus={focusHandler} name={getAlias()} id={getAlias()}  onChange={onValueChange} value={inputValue} />}
        {props.component.format === "date" && 
          <InputMask mask="99/99/9999" inputMode={"numeric"} pattern="[0-9]*" spellCheck={"false"}  maskPlaceholder={formContext.formData?.get('system.country') === 'us' ? usDateFormat : caDateFormat} onBlur={blurHandler} onFocus={focusHandler} name={getAlias()} id={getAlias()}  onChange={onValueChange} value={inputValue} />
        }
        {props.component.format === "zip" && 
          <InputMask mask={formContext.formData?.get('system.country') === 'us' ? "99999" : "*** ***"} inputMode={formContext.formData?.get('system.country') === 'us' ? "numeric" : undefined} pattern={formContext.formData?.get('system.country') === 'us' ? "[0-9]*" : ""} spellCheck={"false"} onBlur={blurHandler} onFocus={focusHandler} name={getAlias()} id={getAlias()}  onChange={onValueChange} value={inputValue} />
        }
        {props.component.format === "phone" && 
          <InputMask mask="+1 (999) 999-9999" inputMode={"numeric"} pattern="[0-9]*" onBlur={blurHandler} onFocus={focusHandler} name={getAlias()} id={getAlias()}  onChange={onValueChange} value={inputValue} />
        }
        {props.component.format === "simplephone" && 
          <InputMask mask="999 999-9999" inputMode={"numeric"} pattern="[0-9]*" onBlur={blurHandler} onFocus={focusHandler} name={getAlias()} id={getAlias()}  onChange={onValueChange} value={inputValue} />
        }
        {props.component.format === "ssn" && 
          <InputMask mask={getSsnMask()} inputMode={"numeric"} pattern="[0-9]*" onBlur={blurHandler} onFocus={focusHandler} name={getAlias()} id={getAlias()}  onChange={onValueChange} value={inputValue} />
        }
        {props.component.format === "ssn4" && 
          <InputMask mask="###-##-9999" inputMode={"numeric"} pattern="[0-9]*" onBlur={blurHandler} onFocus={focusHandler} name={getAlias()} id={getAlias()}  onChange={onValueChange} value={inputValue} />
        }
        {props.component.format === "ein" && 
          <InputMask mask={getEinMask()} inputMode={"numeric"} pattern="[0-9]*" onBlur={blurHandler} onFocus={focusHandler} name={getAlias()} id={getAlias()}  onChange={onValueChange} value={inputValue} />
        }
        {props.component.format === "currency" && 
          <input ref={inputRef} onClick={forceCursor} onKeyDown={forceCursor} onKeyPress={forceCursor} onKeyUp={forceCursor} inputMode={"numeric"} onBlur={blurHandler} onFocus={focusHandler} name={getAlias()} id={getAlias()}  onChange={onValueChange} value={inputValue} />
        }
        {props.component.format === "textarea" && 
          <Fragment>
          <div className="char-count">{getValue().length + "/" + props.component.maxChars}</div>
          <textarea onBlur={blurHandler} maxLength={props.component.maxChars} onFocus={focusHandler} name={getAlias()} id={getAlias()}  onChange={onValueChange} value={inputValue} />
          </Fragment>
        }
        {hasError && props.component.errorMessage && <strong><KeyReplace string={props.component.errorMessage || ''} /></strong>}
        {props.component.hint && <span className="hint"><KeyReplace string={props.component.hint} /></span>}
    </div>
    </Fragment>
  );
}

export default StandardInput;