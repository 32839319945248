import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import './Church-Investors-Fund.scss';


interface IProps {
  
}

const Help:FunctionComponent<IProps> = props => {
  
  


  return (
    <div className="church-investors">
        <h1>Have Questions? We Can Help...</h1>
        <div className="scroll-wrapper">
          <div className="scroll-area">
            <p>For questions or assistance please contact CFR at <a href="mailto:stewardship@cfrministry.org">stewardship@cfrministry.org</a> or call us at <a href="tel:1-800-881-3863">(800) 881-3863</a>.</p>
          </div>
        </div>
        
    </div>
  );
}

export default Help;