import React, { FunctionComponent, Fragment, useContext, useEffect, useState } from 'react';

import {FormContext} from '../../context/FormContext';
import './Loader.scss';
import AcknowledgementUs from '../ChurchInvestorsFund/AcknowledgementUs';
import AcknowledgementCa from '../ChurchInvestorsFund/AcknowledgementCa';
import Help from '../ChurchInvestorsFund/Help';


interface IProps {
  
}



const Loader:FunctionComponent<IProps> = props => {

  const formContext = useContext(FormContext);
      
  return (
    <div className="loader-overlay">
        <div className="loader-overlay-background"></div>
        <div className="loader-box">Processing Your Request <div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
    </div>
  );
}

export default Loader;