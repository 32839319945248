import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import ChristianFinancialResoucesTheme from './ChristianFinancialResources';
import ChurchinvestorsFundTheme from './ChurchInvestorsFund/ChurchInvestorsFundTheme';

interface IProps {
  theme:string;
  resetApp():void;
}


const Theme:FunctionComponent<IProps> = props => {

  return (
    <Fragment> 
        {props.theme === 'cif' &&
            <ChurchinvestorsFundTheme resetApp={props.resetApp}>
                {props.children}
            </ChurchinvestorsFundTheme>
        }
        {props.theme === 'cfr' &&
            <ChristianFinancialResoucesTheme resetApp={props.resetApp}>
                {props.children}
            </ChristianFinancialResoucesTheme>
        }
    </Fragment>
    )
}

export default Theme;