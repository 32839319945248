import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';

import './SendSummary.scss';
import ISendSummary from '../interfaces/SendSummary';
import {FormContext} from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';

interface IProps {
  finished?:boolean;
  component?:ISendSummary;
}

interface Signer {
  name:string;
  title:string;
  email:string;
}

const SendSummary:FunctionComponent<IProps> = props => {
  
  const formContext = useContext(FormContext);

  let [numberHolder, setNumberHolders] = useState<string>("1");
  let [applicationType, setApplicationType] = useState<string>('');

  React.useEffect(() => {
    let numberOfHolders = formContext.formData?.get("investment-application.account-holders.number-of-owners");
    if(numberOfHolders) {
        setNumberHolders(numberOfHolders);
    }
    let applicationTypeS = formContext.formData?.get("investment-application.choose-account-type.account-type-select");
    if(applicationTypeS) {
      setApplicationType(applicationTypeS);
    }    
   }, [formContext.formRevision])

   function getSigners() {
    let signers:Signer[] = [];
    if (props.component) {
      let signerString:string[] = props.component.signers?.split(",") || [];
    if (signerString.length > 0) {
       signerString.forEach((subSigner:string) => {
         let splitSigner:string[] = subSigner.split('|');
         let signer = {name : replaceText(splitSigner[0]), title : replaceText(splitSigner[1]), email : replaceText(splitSigner[2])}
         if (!signer.name.startsWith('{') && !signer.name.endsWith('}')) {
            signers.push(signer);
         }
         
       })
    } 
    }
    

    return signers;
  }

  function replaceText(text:string):string {
    let newString = text;
    if (formContext.formData) {
      Array.from(formContext.formData).forEach((value:[string,string]) => 
        newString = newString.replaceAll("{" + value[0] + "}", value[1])
      )
    }
  
    return newString;
  }

  return (
    <div className="send-summary">
      {!props.component?.v2 &&
        <Fragment>
        {!props.finished && <h2>Review Your Email Information</h2>}
        {props.finished && <h2>Check Your Inbox</h2>}
        <p>
        {numberHolder === '1' && applicationType !== "corporate" && 
        <KeyReplace string={
            !props.finished ? "Once you submit your online investment application, a completed copy of the application will be emailed to the {system.primary-owner-title} at the following email address:"
            : "Once your application is processed, a completed copy of the application will be emailed to the {system.primary-owner-title} at the following email address:"
        }/>}
        {numberHolder === '2' && 
        <KeyReplace string={
            !props.finished ? "Once you submit your online investment application, a completed copy of the application will be emailed to the {system.primary-owner-title} and {system.joint-owner-title} at the following email addresses:"
            : "Once your application is processed, a completed copy of the application will be emailed to the {system.primary-owner-title} and {system.joint-owner-title} at the following email addresses:"
        }/>}
        {applicationType === "corporate" && 
        <KeyReplace string={
            !props.finished ? "Once you submit your online investment application, a completed copy of the application will be emailed to the Organization Representitive at the following email address:"
            : "Once your application is processed, a completed copy of the application will be emailed to the Organization at the following email address:"
        }/>}
        </p>
        {(numberHolder === '1' || numberHolder === '2') && applicationType !== 'corporate' &&
        <div className="email-user">
            <span className="email-user-name"><KeyReplace string={"{investment-application.personal-information.primary-owner-information.first-name} {investment-application.personal-information.primary-owner-information.last-name}"}/></span>
            <span className="email-user-title"><KeyReplace string={"{system.primary-owner-title}"}/></span>
            <span className="email-user-email"><KeyReplace string={"{investment-application.personal-information.primary-owner-information.email-address}"}/></span>
        </div>
        }
        {numberHolder === '2' && applicationType !== 'corporate' &&
            <div className="email-user">
            <span className="email-user-name"><KeyReplace string={"{investment-application.personal-information.joint-owner-information.first-name} {investment-application.personal-information.joint-owner-information.last-name}"}/></span>
            <span className="email-user-title"><KeyReplace string={"{system.joint-owner-title}"}/></span>
            <span className="email-user-email"><KeyReplace string={"{investment-application.personal-information.joint-owner-information.email-address}"}/></span>
            </div>
        }
        {applicationType === 'corporate' && 
        <div className="email-user">
            <span className="email-user-name"><KeyReplace string={"{investment-application.personal-information.rep-information.first-name} {investment-application.personal-information.rep-information.last-name}"}/></span>
            <span className="email-user-title"><KeyReplace string={"Organization Representitive"}/></span>
            <span className="email-user-email"><KeyReplace string={"{investment-application.personal-information.rep-information.email-address}"}/></span>
        </div>
        }
        <p>Once you receive the application, follow the instructions in the email to sign the application online. Our E-Signature process is fast, secure, and convienient, if you have any questions, please contact us.</p>
        </Fragment>
}
        {props.component && props.component.v2 &&
        <Fragment>
          {!props.finished && <h2>Review Your Email Information</h2>}
          {props.finished && <h2>Check Your Inbox</h2>}
          <p><KeyReplace string={props.component.intro || ''} /></p>
          {getSigners().map((signer:Signer, index:number) =>
          <div key={`signer-${index}`} className="email-user">
          <span className="email-user-name">{signer.name}</span>
          <span className="email-user-title">{signer.title}</span>
          <span className="email-user-email">{signer.email}</span>
          </div>
          )}
          <p><KeyReplace string={props.component.outro || ''} /></p>
        </Fragment>
       }
    </div>
  );
}

export default SendSummary;