import React, { Component, FunctionComponent, useContext, useEffect, useState } from 'react';

import './StandardCheck.scss';
import IStandardCheck from '../interfaces/StandardCheck';
import {FormContext} from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';
import VisibilityService from '../../../service/VisibilityService';
 
interface IProps {
  component:IStandardCheck;
  parentAlias:string;
}

const StandardSelect:FunctionComponent<IProps> = props => {
  
  const formContext = useContext(FormContext);

  let [checkedValue, setCheckedValue] = useState<boolean>(getValue());

  function onValueChange(event:React.ChangeEvent<HTMLInputElement>) {
    
    let checked = event.target.checked;
    if(formContext.updateFormData) {
        if (checked) {
            formContext.updateFormData(props.parentAlias + "." + props.component.alias, "selected");
        } else {
            formContext.updateFormData(props.parentAlias + "." + props.component.alias, "");
        }
      
      setCheckedValue(checked);
    }
  }


  function getAlias():string {
    return props.parentAlias + '.' + props.component.alias;
  }


  function getValue():boolean {
    
    let value = formContext.formData?.get(getAlias());
    
    if(value) {
      return true;
    } else {
      return false;
    }
  }



  // React.useEffect(() => {
  //   onValueDefault(Array.from(choices)[0][1]);
  //  }, [choices])

  function showIf(formItem:any):boolean {
    //if (formContext) {
      return VisibilityService.showIf(formItem, formContext);
    //}
    
    
  }

  

  return (
    <div className={"standard-check"}>
        <input type="checkbox" name={getAlias()} id={getAlias()} onChange={onValueChange} checked={checkedValue} value="selected" />
        <label htmlFor={getAlias()}><KeyReplace string={props.component.label} /></label>
        
       
    </div>
  );
}

export default StandardSelect;