import React, { Component, FunctionComponent, useContext, useEffect, useState } from 'react';

import './StandardSelect.scss';
import IStandardSelect from '../interfaces/StandardSelect';
import {FormContext} from '../../../context/FormContext';
import FormItemChoice from '../../../interfaces/FormItemChoice';
import KeyReplace from '../../KeyReplace';
import VisibilityService from '../../../service/VisibilityService';
 
interface IProps {
  component:IStandardSelect;
  parentAlias:string;
  validationHandler(alias:string, isValid:boolean):void;
  formDirty:boolean;
}

const StandardSelect:FunctionComponent<IProps> = props => {
  
  const formContext = useContext(FormContext);

  let [checkedValue, setCheckedValue] = useState<string>(getValue());
  let [isValid, setIsValid] = useState<boolean>();
  let [isTouched, setIsTouched] = useState<boolean>(false);
  let [hasFocus, setHasFocus] = useState<boolean>(false);
  let [hasError, setHasError] = useState<boolean>(false);
  const [choices, setChoices] = useState<Map<string, string>>(new Map<string, string>());

  function onValueChange(event:React.ChangeEvent<HTMLSelectElement>) {
    setIsTouched(true);
    let value = event.target.value;
    if(formContext.updateFormData) {
      formContext.updateFormData(props.parentAlias + "." + props.component.alias, value);
      setCheckedValue(value);
    }
  }

  function onValueDefault(value:string) {
    if(formContext.updateFormData) {
      formContext.updateFormData(props.parentAlias + "." + props.component.alias, value);
      setCheckedValue(value);
    }
  }

  function getAlias():string {
    return props.parentAlias + '.' + props.component.alias;
  }


  function getValue():string {
    
    let value = formContext.formData?.get(getAlias());
    
    if(value) {
      return value;
    } else {
      return '';
    }
  }


  function validate() {
    
    let isFieldValid = true;
    
    

    if(props.component.required) {
      if(!checkedValue || !checkedValue.trim()) {
        isFieldValid = false;  
      } 
    }
    
    

    if(!props.component.dataSource) {
      if(!isSelectionVisible(checkedValue)) {
        isFieldValid = false;
      }
    }
    
    if(choices.size === 1) {
      isFieldValid = true;
    }
    if(!props.component.prompt) {
      isFieldValid = true;
    }

    if(!props.component.required) {
      isFieldValid = true;
    }
      setIsValid(isFieldValid);
      props.validationHandler(getAlias(), isFieldValid);
  }


  function isSelectionVisible(value:string):boolean {
   
    
    let choice:FormItemChoice | undefined = props.component.choices.find((choice:FormItemChoice) => choice.value === value);
    //console.log(choice);
    if(choice) {
      if(showIf(choice)) {
        //console.log('selection visibile' + choice?.label);
        return true;
        
      }
    } 
    //console.log('selection not visible:' + choice?.label)
    //if(formContext.removeFormData) {
    //  formContext.removeFormData(props.parentAlias + "." + props.component.alias);
    //}
    // if (formContext.formData?.get(props.parentAlias + "." + props.component.alias) === choice?.value) {
    //   if(formContext.removeFormData) {
    //   formContext.removeFormData(props.parentAlias + "." + props.component.alias);
    //   }
    // } 
    
    return false
    
  }


  
  React.useEffect(() => {
    
    validate();
   }, [checkedValue])

  React.useEffect(() => {
   let value = getValue()
    if(value !== checkedValue) {
      setIsTouched(true);
      setCheckedValue(value);
      if(formContext.updateFormData) {
        formContext.updateFormData(getAlias(), value);
      }
    }
    setChoices(getOptions()); 
    validate();
  }, [formContext.formRevision])

  // React.useEffect(() => {
  //   onValueDefault(Array.from(choices)[0][1]);
  //  }, [choices])

  function showIf(formItem:any):boolean {
    //if (formContext) {
      return VisibilityService.showIf(formItem, formContext);
    //}
    
    
  }

  
  function blurHandler() {
    setIsTouched(true);
    validate();
    setHasFocus(false);
  }
  
  function focusHandler() {
    
    setHasFocus(true);
  }

  React.useEffect(() => {
    if(isTouched && !isValid) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [hasFocus, isTouched, isValid])

  React.useEffect(() => {
    setChoices(getOptions());
  }, [])

  React.useEffect(() => {
    if(props.formDirty) {
    setIsTouched(true);
    validate();
    }
   }, [props.formDirty])


  function getOptions():Map<string, string> {
      if (props.component.dataSource === 'state') {
        let value = formContext.formData?.get(formContext.formAlias + '.' + props.component.dataSourceReference);
        if(!value) {
          value = formContext.formData?.get(props.component.dataSourceReference);
        }
        if (value === 'us') {
            let cifStates = new Map([
                ['AL', 'Alabama'],
                ['AK', 'Alaska'],
                ['AZ', 'Arizona'],
                //['AR', 'Arkansas'],
                ['CA', 'California'],
                ['CO', 'Colorado'],
                ['CT', 'Connecticut'],
                //['DE', 'Delaware'],
                //?['DC', 'District Of Columbia'],
                //['FL', 'Florida'],
                ['GA', 'Georgia'],
                ['HI', 'Hawaii'],
                ['ID', 'Idaho'],
                ['IL', 'Illinois'],
                ['IN', 'Indiana'],
                ['IA', 'Iowa'],
                ['KS', 'Kansas'],
                //['KY', 'Kentucky'],
                ['LA', 'Louisiana'],
                //['ME', 'Maine'],
                ['MD', 'Maryland'],
                //['MA', 'Massachusetts'],
                ['MI', 'Michigan'],
                ['MN', 'Minnesota'],
                //['MS', 'Mississippi'],
                ['MO', 'Missouri'],
                ['MT', 'Montana'],
                ['NE', 'Nebraska'],
                ['NV', 'Nevada'],
                ['NH', 'New Hampshire'],
                ['NJ', 'New Jersey'],
                //['NM', 'New Mexico'],
                ['NY', 'New York'],
                ['NC', 'North Carolina'],
                ['ND', 'North Dakota'],
                ['OH', 'Ohio'],
                ['OK', 'Oklahoma'],
                ['OR', 'Oregon'],
                ['PA', 'Pennsylvania'],
                //['RI', 'Rhode Island'],
                ['SC', 'South Carolina'],
                ['SD', 'South Dakota'],
                ['TN', 'Tennessee'],
                ['TX', 'Texas'],
                //['UT', 'Utah'],
                //['VT', 'Vermont'],
                ['VA', 'Virginia'],
                ['WA', 'Washington'],
                //['WV', 'West Virginia'],
                ['WI', 'Wisconsin']
                //['WY', 'Wyoming']
            ]);
           return cifStates;
        }
        if (value === 'ca') {
            let caStates = new Map([
              ["AB", "Alberta"],
              ["BC", "British Columbia"],
              ["MB", "Manitoba"],
              ["NB", "New Brunswick"],
              ["NL", "Newfoundland and Labrador"],
              ["NT", "Northwest Territories"],
              ["NS", "Nova Scotia"],
              ["NU", "Nunavut"],
              ["ON", "Ontario"],
              ["PE", "Prince Edward Island"],
              ["QC", "Quebec"],
              ["SK", "Saskatchewan"],
              ["YT", "Yukon Territory"]
            ]);
           return caStates;
        }
        if (!value) {
          let usStates = new Map([
              ['AL', 'Alabama'],
              ['AK', 'Alaska'],
              ['AZ', 'Arizona'],
              ['AR', 'Arkansas'],
              ['CA', 'California'],
              ['CO', 'Colorado'],
              ['CT', 'Connecticut'],
              ['DE', 'Delaware'],
              ['DC', 'District Of Columbia'],
              ['FL', 'Florida'],
              ['GA', 'Georgia'],
              ['HI', 'Hawaii'],
              ['ID', 'Idaho'],
              ['IL', 'Illinois'],
              ['IN', 'Indiana'],
              ['IA', 'Iowa'],
              ['KS', 'Kansas'],
              ['KY', 'Kentucky'],
              ['LA', 'Louisiana'],
              ['ME', 'Maine'],
              ['MD', 'Maryland'],
              ['MA', 'Massachusetts'],
              ['MI', 'Michigan'],
              ['MN', 'Minnesota'],
              ['MS', 'Mississippi'],
              ['MO', 'Missouri'],
              ['MT', 'Montana'],
              ['NE', 'Nebraska'],
              ['NV', 'Nevada'],
              ['NH', 'New Hampshire'],
              ['NJ', 'New Jersey'],
              ['NM', 'New Mexico'],
              ['NY', 'New York'],
              ['NC', 'North Carolina'],
              ['ND', 'North Dakota'],
              ['OH', 'Ohio'],
              ['OK', 'Oklahoma'],
              ['OR', 'Oregon'],
              ['PA', 'Pennsylvania'],
              ['RI', 'Rhode Island'],
              ['SC', 'South Carolina'],
              ['SD', 'South Dakota'],
              ['TN', 'Tennessee'],
              ['TX', 'Texas'],
              ['UT', 'Utah'],
              ['VT', 'Vermont'],
              ['VA', 'Virginia'],
              ['WA', 'Washington'],
              ['WV', 'West Virginia'],
              ['WI', 'Wisconsin'],
              ['WY', 'Wyoming']
          ]);
         return usStates;
      }
        
      }
      if (props.component.choices && props.component.choices.length > 0) {
          
        let map = new Map(props.component.choices.filter((choice:FormItemChoice) => showIf(choice)).map((choice:FormItemChoice) => [choice.value, choice.label]));
        if (!props.component.prompt && !checkedValue) {
          onValueDefault(Array.from(map)[0][1]);
        }
        if (map.size === 1 && checkedValue != Array.from(map)[0][1]) {
          //setCheckedValue(Array.from(map)[0][1]);
          onValueDefault(Array.from(map)[0][1]);
        }
        
        return map
      }
      return new Map([['','']]);
  }

  return (
    <div className={"standard-select " + (hasError ? 'error' : '')}>
        <label htmlFor={getAlias()}><KeyReplace string={props.component.label} />{props.component.required && <span className="required">*</span>}</label>
        <select name={getAlias()} id={getAlias()} onBlur={blurHandler} onFocus={focusHandler} onChange={onValueChange} value={getValue()}>
            {props.component.prompt && Array.from(choices).length > 1 && <option value=''>{props.component.prompt}</option>}
            {Array.from(choices).map((value:[string, string]) =>
                <option key={value[0]} value={value[0]}>{value[1]}</option>
            )}
        </select>
        {hasError && <strong><KeyReplace string={props.component.errorMessage || ''} /></strong>}
    </div>
  );
}

export default StandardSelect;